<template>
	<v-row>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-row no-gutters class="mb-n4">
						<v-col class="d-none d-md-block pr-1">
							<InputDatePicker
								:disabled="carregando"
								v-model="busca.dtinicio"
								label="Data inicial"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<InputDatePicker
								:disabled="carregando"
								v-model="busca.dtfim"
								label="Data final"
								:outlined="true"
								:dense="true"
							/>
						</v-col>
						<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
							<v-sheet class="text-center" height="100%">
								<v-row class="pa-3">
									<v-col cols="6" class="mb-n3 px-2">
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtinicio"
											label="Data inicial"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6" class="mb-n3 px-2">
										<InputDatePicker
											:disabled="carregando"
											v-model="busca.dtfim"
											label="Data final"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="listar(), sheet = !sheet">Filtrar</v-btn>
									</v-col>
								</v-row>
							</v-sheet>
						</v-bottom-sheet>
						<v-col class="pl-1">
							<v-autocomplete
								class="d-flex d-md-none"
								:items="filiaisFiltro"
								clearable
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idfilial"
								@keydown.enter="listar()"
								append-outer-icon="mdi-menu"
								@click:append-outer="sheet = !sheet"
							></v-autocomplete>
							<v-autocomplete
								class="d-none d-md-block"
								:items="filiaisFiltro"
								clearable
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idfilial"
								@keydown.enter="listar()"
							></v-autocomplete>
						</v-col>
						<v-col cols="auto" class="ml-2">
							<v-btn
								:disabled="carregando"
								class="mt-1"
								color="primary"
								elevation="0"
								fab
								x-small
								@click="listar()"
							>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<small
						v-if="dados.total"
						class="float-right mt-n2"
					>Data última atualização: {{dados.total[0].dataatualizacao}}</small>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" v-if="carregandoSkeleton">
			<v-skeleton-loader type="table-tbody" />
		</v-col>
		<v-col v-else cols="12">
			<v-card color="primary" dark>
				<v-card-title>Total</v-card-title>
				<v-divider />
				<v-simple-table light dense class="rounded-t-0">
					<thead>
						<tr>
							<th>Mês</th>
							<th>Descrição</th>
							<th>Sobra</th>
							<th>Falta</th>
							<th>Perda</th>
							<th>Saldo</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(t,i) in dados.total" :key="i">
							<td>{{t.mes}}</td>
							<td>{{t.descricao}}</td>
							<td>{{t.sobra | formataDinheiro}}</td>
							<td>{{t.falta | formataDinheiro}}</td>
							<td>{{t.perda | formataDinheiro}}</td>
							<td>{{(t.sobra - t.falta - t.perda) | formataDinheiro}}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr class="font-weight-bold">
							<td>Total</td>
							<td></td>
							<td>{{dados.total.map(v => v.sobra).reduce((a,v) => a + v) | formataDinheiro}}</td>
							<td>{{dados.total.map(v => v.falta).reduce((a,v) => a + v) | formataDinheiro}}</td>
							<td>{{dados.total.map(v => v.perda).reduce((a,v) => a + v) | formataDinheiro}}</td>
							<td>{{(dados.total.map(v => v.sobra).reduce((a,v) => a + v)-dados.total.map(v => v.falta).reduce((a,v) => a + v)-dados.total.map(v => v.perda).reduce((a,v) => a + v)) | formataDinheiro}}</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card>
		</v-col>
		<v-col cols="12" v-if="carregandoSkeleton">
			<v-skeleton-loader type="table-tbody" />
		</v-col>
		<v-col v-else cols="12" v-for="(filial, i) in dados.lista" :key="i">
			<v-card :color="tabelaStatus(filial)" dark>
				<v-card-title>{{filial.filial}}</v-card-title>
				<v-divider />
				<v-simple-table light dense class="rounded-t-0">
					<thead>
						<tr>
							<th class="text-center">Cód. Filial</th>
							<th>Mês</th>
							<th>Descrição</th>
							<th>Sobra</th>
							<th>Falta</th>
							<th>Perda</th>
							<th>Saldo</th>
						</tr>
					</thead>
					<tbody v-for="(mes,i) in filial.meses" :key="i">
						<tr>
							<td class="text-center">{{mes.idfilial}}</td>
							<td>{{mes.mes}}</td>
							<td>{{mes.descricao}}</td>
							<td>{{mes.sobra | formataDinheiro}}</td>
							<td>{{mes.falta | formataDinheiro}}</td>
							<td>{{mes.perda | formataDinheiro}}</td>
							<td>{{(mes.sobra - mes.falta - mes.perda) | formataDinheiro}}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr class="font-weight-bold">
							<td></td>
							<td>Total</td>
							<td></td>
							<td>{{filial.meses.map(v => v.sobra).reduce((a,v) => a + v) | formataDinheiro}}</td>
							<td>{{filial.meses.map(v => v.falta).reduce((a,v) => a + v) | formataDinheiro}}</td>
							<td>{{filial.meses.map(v => v.perda).reduce((a,v) => a + v) | formataDinheiro}}</td>
							<td>{{(filial.meses.map(v => v.sobra).reduce((a,v) => a + v)-filial.meses.map(v => v.falta).reduce((a,v) => a + v)-filial.meses.map(v => v.perda).reduce((a,v) => a + v)) | formataDinheiro}}</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker.vue";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";

export default {
	mixins: [mixinFilial],
	components: { InputDatePicker },
	name: "BalancoLista",
	data: () => ({
		carregando: false,
		carregandoSkeleton: false,
		busca: {
			dtinicio: `${new Date().getFullYear()}-01-01`,
			dtfim: `${new Date().getFullYear()}-12-31`,
		},
		sheet: false,
		dados: {},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar() {
			this.carregando = true;
			this.carregandoSkeleton = true;
			return axios
				.post(`${this.backendUrl}balanco/listar`, {
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		tabelaStatus(array) {
			if (
				array.meses
					.map((v) => v.descricao)
					.filter((v) => v == "Balanço").length ==
				array.meses
					.map((v) => v.descricao)
					.filter((v) => v == "Demais ajustes").length
			) {
				return "primary";
			} else {
				return "#951015";
			}
		},
	},
	created() {
		this.listar();
	},
};
</script>

<style>
</style>